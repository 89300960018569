#surveyApp {
	header {
		height: 80px;
		display: flex;
		flex-direction: row;
		align-self: center;
		padding-left: 100px;
		position: relative;
		width: 100%;
		background-color: #fff;
		z-index: 2;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		.logoContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			color: #222;
			cursor: pointer;

			img {
				width: 32px;
				height: 32px;
			}

			span {
				font-weight: 400;
				font-size: 18px;
				margin-left: 8px;
			}
		}
	}

	@media screen and (max-width: 992px) {
		footer {
			height: 60px !important;
			padding: 0 10px !important;
			justify-content: space-between !important;
		}

		footer .logoContainer img {
			width: 142px !important;
		}

		footer .contractsContainer a {
			font-size: 13px !important;
			font-weight: 600 !important;
		}

		#form_survey {
			margin-top: 20px !important;
			top: 20px !important;
		}
	}

	footer {
		height: 80px;
		display: flex;
		flex-direction: row;
		align-self: center;
		padding: 0 40px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #fff;
		z-index: 2;

		.logoContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			img {
				width: 172px;
				height: 16px;
			}

			span {
				font-weight: 400;
				font-size: 18px;
				margin-left: 8px;
			}
		}

		.contractsContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: auto;

			a {
				margin-left: 16px;
				text-decoration: none;
				color: black;
				font-weight: bold;
				cursor: pointer;
			}
		}
	}
}

body,
html {
	font-family: Inter, sans-serif;

	/* 👇️ or use Operating system default fonts 👇️ */
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	#form_survey {
		margin-top: 80px !important;
		top: 80px !important;
	}

	margin: 0;
	height: 100vh;
	iframe {
		display: none;
	}

	a {
		text-decoration: none;
	}

	#radiogroupquestions,
	#radiogroupquestions1,
	#radiogroupquestions2,
	#radiogroupquestions3,
	#radiogroupquestions4,
	#radiogroupquestions5,
	#radiogroupquestions6,
	#radiogroupquestions7,
	#radiogroupquestions8,
	#radiogroupquestions9,
	#radiogroupquestions10 {
		flex-direction: column !important;
	}

	.sv_image_image {
		width: 100%;
		min-width: 100%;
		min-height: 100%;
		height: 100%;
	}

	.conditionsModal {
		width: 100%;
		height: 400px;
		overflow: hidden scroll;
		border-radius: 8px;
		margin: 0;

		.titleContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			padding-bottom: 20px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);

			.title {
				font-weight: 700;
				font-size: 32px;
				line-height: 32px;
				color: #222;
			}

			.closeButton {
				width: 18px;
				height: 18px;
				margin-left: auto;
				cursor: pointer;
			}
		}

		.contentContainer {
			display: flex;
			flex-direction: column;

			.smallTitle {
				display: flex;
				font-weight: 700;
				font-size: 24px;
				line-height: 32px;
				color: #2b2b2b;
				margin-top: 20px;
			}

			.bigTitle {
				font-weight: 700;
				font-size: 24px;
				line-height: 32px;
			}

			.text {
				margin-top: 20px;
				line-height: 24px;
			}

			li {
				margin-top: 4px;
				line-height: 24px;

				&:first-of-type {
					margin-top: 0;
				}
			}

			th,
			tr,
			td {
				border: 1px solid #dddddd;
				border-spacing: 0 !important;
				padding: 0;
			}

			th {
				border-bottom: none;
			}
		}

		.successModal {
			align-items: center;
			justify-content: flex-start;
			height: 100%;
			overflow: auto;
			padding-top: 30px;
		}

		&-modalIcon {
			width: 84px;
			height: 84px;
		}
	}

	.conditionsModal.successModal {
		overflow: hidden;
	}

	.ReactModal__Content {
		overflow-y: auto !important;
		overflow-x: hidden !important;
	}

	@media screen and (max-width: 992px) {
		:root {
			--app-height: 100%;
			--app-width: 100%;
		}

		header {
			justify-content: center;
			padding: 0 !important;
		}

		.homePage .heroContainer {
			height: 300px;
		}

		.homePage .heroContainer .title {
			font-size: 24px;
		}

		.homePage .heroContainer .description {
			font-size: 14px;
			padding: 0 20px;
			line-height: 16px;
		}

		.homePage .infoContainer {
			margin: 0;
			padding-top: 10px;
			margin-top: 10px;
			width: 80%;
			padding-bottom: 10px;
		}

		.homePage .infoContainer .title {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.homePage .infoContainer .description {
			font-size: 14px;
			line-height: 18px;
		}

		.homePage .cardContainer {
			margin: 20px 0;
		}

		.homePage .cardContainer .card {
			width: 25%;
			height: 130px;
			margin-left: 7%;
		}

		.homePage .cardContainer .card .cardBadge {
			width: 30px;
			height: 30px;
			font-size: 14px;
		}

		.homePage .cardContainer .card .cardText {
			font-size: 12px;
			line-height: 16px;
			margin: 0;
			margin-top: 10px;
			padding: 0 10px;
		}

		.homePage .startContainer {
			width: 90%;
			margin: 0;
		}

		.homePage .startContainer .arrowImage {
			width: 120px;
			height: 60px;
		}

		.homePage .startContainer .secondImage {
			margin-top: 0;
			width: 90%;
			height: auto;
		}

		.homePage .startContainer .title {
			font-size: 20px;
			margin-top: 10px;
			width: 80%;
			text-align: center;
		}

		.homePage .startContainer .description {
			padding-bottom: 14px;
			font-size: 14px;
			line-height: 20px;
			margin-top: 0;
			padding-bottom: 0;
			width: 80%;
			text-align: center;
		}

		.homePage .startContainer .legalText {
			font-size: 12px;
			margin-bottom: 10px;
		}

		#form_survey form .sv-container-modern {
			width: 90%;
		}

		.sv_progress-buttons__list {
			padding-left: 0 !important;
		}

		.surveyPage #form_survey form .sv-container-modern ul li:after {
			width: 68%;
		}

		.metric-widget .metric .title {
			font-size: 24px;
			line-height: 28px;
			margin-top: 0;
		}

		.sv-body__page {
			margin-top: 10px !important;
		}

		.metric-widget .metric .description {
			font-size: 16px;
			line-height: 20px;
		}

		.sv-row:not(:last-child) {
			padding-bottom: 15px;
		}

		.radio-question .radio label {
			font-size: 20px;
			margin-bottom: 0;
			line-height: 20px;
			margin-top: 10px;
		}

		.radio-question .radio .MuiFormControlLabel-label {
			font-size: 18px;
		}

		.MuiFormGroup-root {
			margin-top: 10px;
		}

		.offersPage .heroContainer {
			padding-top: 20px;
		}

		.offersPage .heroContainer .title {
			font-size: 24px;
		}

		.offersPage .heroContainer .description {
			font-size: 14px;
			padding: 0 20px;
		}

		.offersPage .seeResultsContainer {
			width: 70%;
		}

		.offersPage .seeResultsContainer {
			width: 100%;
		}

		.offerCard,
		.offer {
			width: 180px;
			min-width: 180px;
			max-width: 180px;
		}

		.offer .textArea .offerTitle {
			font-size: 14px;
		}

		.offersPage .seeResultsContainer .withoutOffers {
			font-size: 15px;
		}

		.finalPage .heroContainer {
			width: 90%;
			padding: 20px;
		}

		.finalPage .heroContainer .title {
			font-size: 24px;
			margin-bottom: 20px;
		}

		.finalPage .heroContainer .finalHeroImage {
			width: 80%;
		}

		.finalPage .heroContainer .description {
			width: 80%;
			font-size: 16px;
		}

		.finalPage .formContainer {
			width: 80%;
		}

		.ReactModal__Content {
			width: 80% !important;
		}

		.conditionsModal .titleContainer .title {
			font-size: 24px;
			line-height: 24px;
			padding-top: 8px;
		}

		.conditionsModal .contentContainer .smallTitle {
			font-size: 20px;
			line-height: 22px;
		}

		.conditionsModal .contentContainer .text {
			font-size: 14px;
			line-height: 20px;
		}

		.conditionsModal .contentContainer .bigTitle {
			font-size: 20px;
			line-height: 24px;
		}
	}

	.nameInput {
		align-self: center;
		width: 80%;
		margin-left: 10%;
		margin-right: 10%;
		height: 40px;
		font-size: 18px;
		font-weight: 500;
	}

	.nameInputLabel {
		height: auto !important;
		margin-bottom: 0% !important;
		color: black;
		font-weight: bold;
		padding-bottom: 10px;
	}
}

iframe {
	display: none !important;
}

